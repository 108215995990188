body {
  background: #000;
  color: #fff;
}

a {

  color: #fff;
}

.App {
  text-align: left;
  font-family: "Helvetica", arial, sans-serif;
  margin-left:20px;
  margin-right: 20px;
}


.PlazaHeader {
  font-family: ten-oldstyle, "Times New Roman", times, serif;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.4rem;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 20px;
  width: 97%;
  z-index: 100;
}

.PlazaHeader .SocialLinks {
  display: flex;
  flex-direction: row;
}

.PlazaHeader .SocialLinks div {
  padding-left: 20px;

}


.InfoBox {
  display:flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom:20px;
  font-family: "Helvetica", arial, sans-serif;
  font-size: 3rem;
  line-height: 3.5rem;
  z-index: 100;
}

.PlazaChannel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 100px;
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
}

.PlazaBox {
  animation: fadeIn 1s;
  display: flex;
  align-self: center;
  margin: 1rem;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-basis: 0;
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
  margin-bottom: 6rem;
  margin-right: 1.5rem;
  border:  1px solid #fff;
}

.PlazaBox .ContentBox {
  display: flex;
  
  margin: 0;
  padding: 0;
}

.PlazaText {
  font-family: ten-oldstyle, "Times New Roman", times, serif;
  border:  1px solid #FFFFFF;
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  flex-basis: 30%;

}

.PlazaMeta {
  font-family: "Helvetica", arial, sans-serif;
  line-height: 1.3rem;
  font-size: 1rem;
  padding: 20px 5px 10px 10px;
  color: #FFF;

}

.PlazaMetaSource {
  color: #999;
  padding: 10px 5px 10px 10px;
}

.PlazaMetaText{
  font-family: "Helvetica", arial, sans-serif;
  line-height: 1.3rem;
  font-size: 1rem;
  padding: 20px 5px 10px 0px;
  color: #FFF;
}

.PlazaVideoBox {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.PlazaBgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0
}

@media (min-aspect-ratio: 16/9) {
  .PlazaVideoBox iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
    
@media (max-aspect-ratio: 9/16) {
  .PlazaVideoBox iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

@media screen and (max-width: 600px) {
  .PlazaChannel {
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding-top: 150px;

  }

  .PlazaHeader {
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;    
  }

  .PlazaHeader div {
    padding-bottom: 10px;
  }

 .PlazaHeader .SocialLinks div  {
    padding: 0 20px 0 0;
  }

  .PlazaBox {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  .PlazaBox img {
    width: 100%;
  }

  .PlazaBox .ContentBox video {
    width: 100%;
  }

  .PlazaText {

    padding: 2rem;
    flex-basis: 100%;

  }
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}